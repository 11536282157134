<template>
  <div>
    <div class="columns">
      <div class="column">
        <div>
          Events
          <a @click.prevent="create_event">
            <i class="fa fa-plus"></i>
          </a>
        </div>
        <ag-grid-vue
          style="width: 100%; height: 200px"
          class="ag-theme-balham"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :animateRows="true"
          @grid-ready="onGridReady"
          :context="context"
        >
        </ag-grid-vue>
      </div>
      <div v-if="create_form || edit_form" class="column is-5">
        <DaForm v-if="create_form" @submitted="submitEvent" :form="create_form">
        </DaForm>
        <DaForm v-if="edit_form" @submitted="submitEvent" :form="edit_form">
        </DaForm>
      </div>
    </div>
    <!-- {{ selectedEvent }} -->
    <div v-if="selectedEvent.id && ticket_types" class="">
      <div class="columns border-t-2 border-slate-200 mt-3">
        <div class="column is-12">
          <ticket-types-grid :event_guid="event_guid"></ticket-types-grid>
        </div>
        <div class="column is-8">
          <div class=""></div>
          <div
            class="px-2"
            style="border: solid 1px #dbdbdb; border-width: 0px 1px"
          ></div>
        </div>
      </div>
    </div>
    <div v-if="palette_form" class="column is-5">
      <div class="columns border-t-2 border-slate-200 mt-3">
        <div class="column is-12">
          <ColorPalette :event_guid="event_guid"> </ColorPalette>
        </div>
      </div>
    </div>
    <div v-if="selectedEvent.id && locations" class="">
      <div class="columns border-t-2 border-slate-200 mt-3">
        <div class="column is-12">
          <!-- <tickets-grid :selected_item="selected_item"></tickets-grid> -->
          <locations-grid
            :event_guid="event_guid"
            :event="selectedEvent"
          ></locations-grid>
        </div>
        <div class="column is-8">
          <div class=""></div>
          <div
            class="px-2"
            style="border: solid 1px #dbdbdb; border-width: 0px 1px"
          ></div>
        </div>
      </div>
    </div>
    <div v-if="selectedEvent.id && warehouses" class="">
      <div class="columns border-t-2 border-slate-200 mt-3">
        <div class="column is-12">
          <!-- <tickets-grid :selected_item="selected_item"></tickets-grid> -->
          <warehouses-grid :event_guid="event_guid"></warehouses-grid>
        </div>
        <div class="column is-8">
          <div class=""></div>
          <div
            class="px-2"
            style="border: solid 1px #dbdbdb; border-width: 0px 1px"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
import createEvent from "./forms/events/create";
import moment from "moment";
import TicketTypesGrid from "./components/ticket-types-grid";
import LocationsGrid from "../locations/index.vue";
import WarehousesGrid from "./components/warehouses-grid";
import ColorPalette from "./components/color-palette";
import editEvent from "./forms/events/edit";
import EditViewButtons from "@/components/buttonscell/EditViewButtons.vue";
import CheckboxCell from "@/components/checkboxcell/CheckboxCell.vue";
import EventServices from "@/services/Event";
import PointOfSale from "@/services/PointOfSale";
import helpers from "@/helpers.js";

export default {
  components: {
    AgGridVue,
    DaForm,
    LocationsGrid,
    // TicketsGrid,
    TicketTypesGrid,
    ColorPalette,
    WarehousesGrid,
  },

  beforeMount() {
    this.context = { componentParent: this };
  },
  data() {
    return {
      create_form: null,
      edit_form: null,
      context: null,
      rowData: [],
      selected_item: [],
      has_tickets_flag: 0,
      ticket_types: {},
      event_guid: "",
      event_type: 0,
      cities: [],
      locations: 0,
      selectedEvent: {},
      palette_form: null,
      warehouses: [],
      columnDefs: [
        {
          headerName: "Kodi",
          field: "code",
          filter: true,
          floatingFilter: true,
          sortable: true,
          sort: "asc",
        },
        {
          headerName: "Emri",
          field: "name",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Data e fillimit",
          field: "starts_at",
          filter: true,
          valueFormatter: helpers.dateFormatter,
          floatingFilter: true,
          sortable: true,
        },

        {
          headerName: "Data e mbarimit",
          field: "ends_at",
          filter: true,
          valueFormatter: helpers.dateFormatter,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Vendndodhja",
          field: "city",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Ka bileta",
          filter: false,
          field: "has_tickets",
          floatingFilter: true,
          sortable: true,
          cellRenderer: CheckboxCell,
          cellStyle: function () {
            return {
              textAlign: "center",
            };
          },
        },
        {
          headerName: "Ne terren",
          filter: false,
          field: "type",
          floatingFilter: true,
          sortable: true,
          cellRenderer: CheckboxCell,
          cellStyle: function () {
            return {
              textAlign: "center",
            };
          },
        },
        {
          headerName: "Aktiv",
          field: "active",

          cellRenderer: CheckboxCell,
          cellStyle: function () {
            return {
              textAlign: "center",
            };
          },
          filter: false,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Krijuesi",
          field: "creator",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Action",
          cellRenderer: EditViewButtons,
          cellRendererParams: this.context,
        },
      ],
      gridApi: null,
      gridColumnApi: null,
      rowSelection: "single",
    };
  },
  methods: {
    create_event() {
      this.edit_form = null;
      let abs = [
        { id: "0", label: "Local" },
        { id: "1", label: "Foreign" },
      ];

      this.create_form = createEvent.form(this.cities, abs);
    },
    dateFormatter(params) {
      return moment(params.value).format("DD/MM/YYYY HH:mm");
    },
    async open(mode, event) {
      if (mode == "1") {
        this.create_form = null;
        this.palette_form = null;
        this.warehouses = null;
        this.ticket_types = null;
        if (event) {
          this.edit_form = null;
          setTimeout(() => {
            let form = editEvent.form(event, this.cities);

            this.edit_form = form;
            this.selectedEvent = event;
          });
        }
      } else if (mode == "0") {
        let temp = event;

        this.rowData = await EventServices.getEvents();
        this.palette_form = null;
        this.ticket_types = 1;

        this.create_form = null;
        this.edit_form = null;
        this.selectedEvent = null;
        this.warehouses = null;
        this.locations = 0;
        this.selectedEvent = temp;

        this.event_guid = event.guid;

        // this.has_tickets_flag = 1; //todo fix
      } else if (mode == "2") {
        this.create_form = null;
        this.edit_form = null;
        this.warehouses = null;
        this.locations = 0;
        this.event_guid = event.guid;
        this.ticket_types = 0;

        this.palette_form = 1;
      } else if (mode == "3") {
        this.create_form = null;
        this.selectedEvent = {};
        this.locations = 0;

        this.edit_form = null;
        this.warehouses = 1;
        this.ticket_types = 0;

        let temp = event;
        this.rowData = await EventServices.getEvents();

        this.selectedEvent = temp;
        this.palette_form = null;

        this.event_type = event.type;
        this.event_guid = event.guid;
      } else if (mode == "4") {
        this.locations = 0;

        let temp = event;
        this.rowData = await EventServices.getEvents();
        this.locations = 1;
        this.palette_form = null;
        this.ticket_types = 0;
        // debugger; // eslint-disable-line no-debugger

        this.create_form = null;
        this.edit_form = null;
        this.selectedEvent = null;
        this.warehouses = null;
        this.selectedEvent = temp;

        this.event_guid = temp.guid;
      }
    },

    async submitEvent(e) {
      var notify_text = "";

      if (this.create_form != null) {
        let myData = await EventServices.createEvent(e);
        if (myData == "error") {
          notify_text = "Ju lutemi kontrolloni datat";
          this.$toast.error(notify_text);
          return 0;
        }
        this.rowData.push(myData);
        notify_text = "Eventi u krijua me sukses!";
        this.create_form = null;
        this.$root.selected_one = {};
      } else {
        e.guid = this.selectedEvent.guid;
        let eventi = await EventServices.editEvent(e);
        if (eventi == "error") {
          notify_text = "Ju lutemi kontrolloni datat";
          this.$toast.error(notify_text);
          return 0;
        }
        this.rowData = [
          ...this.rowData.map((ev) => {
            if (ev.guid == eventi.guid) ev = { ...eventi };
            return ev;
          }),
        ];

        notify_text = "Eventi u modifikua me sukses!";
        this.edit_form = null;
        this.$root.selected_one = {};
      }
      this.$toast.success(notify_text);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
    },
  },

  async created() {
    this.rowData = await EventServices.getEvents();
    this.cities = await PointOfSale.getCities();
    // debugger; // eslint-disable-line no-debugger
  },
};
</script>

<style scoped></style>
