<template>
  <div>
    <div class="columns">
      <div class="column is-3">
        <div>
          Lista e Zonave
          <a @click.prevent="create_location">
            <i class="fa fa-plus"></i>
          </a>
        </div>
        <table class="mt-4 table">
          <thead>
            <tr>
              <th>Kodi</th>
              <th>Emri</th>
              <th>Eventi</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="location in locations" :key="location.id">
              <td>{{ location.code }}</td>
              <td>{{ location.name }}</td>
              <td>{{ location.event_name }}</td>
              <td>
                <div class="columns">
                  <div class="column is-6">
                    <a @click.prevent="edit_location(location)"
                      ><i class="fa fa-pencil"></i
                    ></a>
                  </div>
                  <!-- {{ route_param }} -->
                  <div class="column is-6">
                    <a @click.prevent="show_activities(location)"
                      ><i class="fa fa-folder"></i
                    ></a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column is-4" v-if="form_edit || form_create">
        <DaForm
          v-if="form_create"
          @submitted="submitLocation"
          :form="form_create"
        >
        </DaForm>

        <DaForm v-if="form_edit" @submitted="submitLocation" :form="form_edit">
        </DaForm>
      </div>
      <!-- <pre>{{ event }}</pre> -->
      <div class="column is-9" v-if="selectedLocation && activities">
        <activity-grid
          :location="selectedLocation"
          :event="route_param"
        ></activity-grid>
      </div>
    </div>
  </div>
</template>

<script>
import DaForm from "@/components/daform/index.vue";
import createLocation from "./forms/create";
import editLocation from "./forms/edit";
import ActivityGrid from "../activities/index.vue";
import LocationsServices from "@/services/Location";
export default {
  components: {
    DaForm,
    ActivityGrid,
  },
  props: ["event_guid", "event"],
  beforeMount() {
    this.context = { componentParent: this };
  },
  data() {
    return {
      locations: [],
      events: [],
      context: null,
      selectedLocation: {},
      form_create: null,
      activities: 0,
      form_edit: null,
      mode: "",
    };
  },
  methods: {
    // async getLocations() {
    //     var all_locations = await LocationsServices.getLocations()
    //     this.locations.push(all_locations)
    // },
    create_location() {
      this.form_edit = null;
      this.activities = 0;

      if (typeof this.$route.params.event_guid !== "undefined") {
        this.form_create = createLocation.form(this.$route.params.event_guid);
      } else this.form_create = createLocation.form(this.event_guid);
    },
    edit_location(lo) {
      this.form_create = null;
      this.activities = 0;
      this.form_edit = null;
      setTimeout(() => {
        let form = editLocation.form(lo, this.route_param);

        this.form_edit = form;
        this.selectedLocation = lo;
      });
    },
    async show_activities(lo) {
      this.activities = 0;
      this.form_create = null;
      this.form_edit = null;
      this.locations = await LocationsServices.getLocations(this.route_param);

      this.activities = 1;
      this.selectedLocation = lo;
    },
    async submitLocation(l) {
      var notify_text = "";
      if (this.form_create != null) {
        // this.selectedEvent = this.route_param

        let location = await LocationsServices.createLocation(
          l,
          this.route_param
        );
        l.event_guid = this.route_param;
        var a = this.locations;
        a.push(location);
        this.locations = [...a];
        notify_text = "Vendodhja u krijua me sukses";
        this.form_create = null;
        this.$root.selected_one = {};
      } else {
        l.guid = this.selectedLocation.guid;

        let location = await LocationsServices.editLocation(
          l,
          this.route_param
        );
        this.locations = [
          ...this.locations.map((a) => {
            if (a.guid == location.guid) {
              a = location;
            }

            return a;
          }),
        ];
        notify_text = "Vendodhja u modifikua me sukses";
        this.form_edit = null;
        this.$root.paletteForm = 0;
      }
      this.$toast.success(notify_text);
    },
  },

  computed: {
    route_param() {
      return this.event_guid;
    },
  },
  async created() {
    this.locations = await LocationsServices.getLocations(this.route_param);

    this.events = await LocationsServices.getEvents();
  },
};
</script>

<style lang="scss" scoped></style>
