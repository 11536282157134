export default {
    form: (event_guid) => {
      return {
        name: "Krijo Zone",
        submit: {
          label: "Krijo",
          action: "submitLocation",
        },
        fields: [
          [
            {
              value: "",
              name: "code",
              label: "Kodi",
              type: "text-field",
              style: "flex-grow: 0.3",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
              unique: {
                model: "location",
                field: "code",
                where: [
                  {
                    column: 'event_guid',
                    condition: '=',
                    value: event_guid
                  }
                ],
              },
              left_icon: "fa-solid fa-hashtag",
            },
            {
              value: "",
              name: "name",
              label: "Emri",
              type: "text-field",
              style: "flex-grow: 0.6",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
            },
          ],
        ],
      };
    },
  };
  
  