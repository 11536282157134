<template>
  <div>
    <div class="columns">
      <div class="column is-5">
        <div>
          <!-- <pre>   {{ ticket_types }} </pre> -->
          <!-- <pre>   {{ event_guid }} </pre> -->

          Shitesit
          <a @click.prevent="create_vendor">
            <i class="fa fa-plus"></i>
          </a>
        </div>
        <ag-grid-vue
          style="width: 92%; height: 200px"
          class="ag-theme-balham"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :animateRows="true"
          @grid-ready="onGridReady"
          :context="context"
        >
        </ag-grid-vue>
      </div>

      <div class="column is-3" v-if="edit_form || create_form">
        <DaForm
          v-if="create_form"
          @submitted="submitVendor"
          :form="create_form"
        ></DaForm>
        <DaForm
          v-if="edit_form"
          @submitted="submitVendor"
          :form="edit_form"
        ></DaForm>
      </div>
      <div v-if="selected_item.id" class="column is-6">
        <Pos
          :warehouse="selected_warehouse"
          :warehouse_id="selected_warehouse.guid"
          :event_guid="event_guid"
        ></Pos>
      </div>
      <div class="column is-8">
        <div class=""></div>
        <div
          class="px-2"
          style="border: solid 1px #dbdbdb; border-width: 0px 1px"
        ></div>
      </div>
    </div>
    <!-- {{ selected_item.id }} -->
  </div>
</template>
<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import VendorButtonsCell from "@/components/buttonscell/VendorButtonsCell.vue";
import CreateVendor from "../forms/vendors/create";
import EditVendor from "../forms/vendors/edit";
import DaForm from "@/components/daform/index.vue";
import EventServices from "@/services/Event";
import Pos from "../components/pos-grid";

import { AgGridVue } from "ag-grid-vue3";
export default {
  props: ["event_guid"],
  components: {
    AgGridVue,
    DaForm,
    Pos,
  },
  data() {
    return {
      context: null,
      rowData: [],
      has_tickets_flag: 0,
      create_form: null,
      edit_form: null,
      type_guid: {},
      selected_warehouse: {},
      columnDefs: [
        {
          headerName: "Emri",
          field: "name",
          filter: true,
          floatingFilter: true,
          sortable: true,
          sort: "asc",
          width: 50,
        },

        {
          headerName: "Kodi",
          field: "code", //TODO : RENDERIZIM ME EURO
          filter: true,
          floatingFilter: true,
          sortable: true,
          width: 50,
        },
        {
          headerName: "Action",
          cellRenderer: VendorButtonsCell,
          cellRendererParams: this.context,
          width: 50,
        },
      ],
      gridApi: null,
      gridColumnApi: null,
      selected_item: {},
      selectedVendor: {},
      rowSelection: "single",
    };
  },

  beforeMount() {
    this.context = { componentParent: this };
  },

  methods: {
    create_vendor() {
      this.edit_form = null;
      this.selected_item = {};
      this.selected_warehouse = {};

      this.create_form = CreateVendor.form();
    },

    async open(mode, vendor) {
      if (mode == "1") {
        //editimi
        this.create_form = null;
        if (vendor) {
          this.edit_form = null;
          setTimeout(() => {
            let form = EditVendor.form(vendor);

            this.edit_form = form;
            this.selectedVendor = vendor;
            this.selected_item = {};
            this.selected_warehouse = {};

          });
        }
      } else if (mode == "2") {
        this.create_form = null;
        this.edit_form = null;
        this.selected_item = {};
        this.selected_warehouse = {};

        let temp = vendor;
        this.rowData = await EventServices.getVendors({
          guid: this.event_guid,
        });
        this.selected_item = temp;
        // // this.event_guid = event.guid;
        // this.type_guid = vendor.guid;
        this.create_form = null;

        this.selected_warehouse = temp;

      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
    },
    async submitVendor(vendor) {
      var notify_text = "";
      let ev_v = {};
      ev_v = {
        warehouses: vendor,
        event: this.event_guid,
      };
      if (this.create_form != null) {
        this.rowData.push(await EventServices.createVendor(ev_v));
        notify_text = "Magazina u krijua me sukses!";
        this.create_form = null;
        this.$root.selected_one = {};

      } else {
        ev_v.guid = this.selectedVendor.guid;
        let warehouse = await EventServices.editVendor(this.event_guid, ev_v);

        this.rowData = [
          ...this.rowData.map((tit) => {
            if (tit.guid == warehouse.guid) tit = { ...warehouse };
            return tit;
          }),
        ];

        notify_text = "Magazina u modifikua me sukses!";
        this.edit_form = null;
        this.$root.selected_one = {};
      }
      this.$toast.success(notify_text);
    },
  },
  async created() {
    this.rowData = await EventServices.getVendors({
      guid: this.event_guid,
    });
    // debugger; // eslint-disable-line no-debugger
  },
};
</script>
