<template>
  <div class="buttons-block is-flex is-justify-content-space-between">
    <a href="" @click.prevent="open_row(1)">
      <i title="Modifiko" class="fa fa-pencil"></i>
    </a>
  </div>
</template>

<script>
export default {
  props: ["params"],
  data() {
    return {};
  },
  methods: {
    open_row(m) {
      this.params.context.componentParent.open(m, this.params.data);
    },
  },
};
</script>

<style>
.buttons-block {
  max-width: 70px;
  margin: auto;
}
</style>
