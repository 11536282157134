export default{
    form: (lo, event_guid) => {
        return {
            name: "Modifiko Zonat",
            submit: {
                label: "Modifiko Zonat",
                action: 'submitLocation'
            },
            fields: [

                [{
                    value: lo.code,
                    name: 'code',
                    label: 'Kodi',
                    type: 'text-field',
                    required: true,
                    required_text: 'Kjo fushe eshte e detyrueshme',
                    unique: {
                        model: 'location',
                        field: 'code',
                        where: [
                            {
                            column: 'guid',
                            condition: '!=',
                            value: lo.guid
                        },
                        {
                            column: 'event_guid',
                            condtion: '=',
                            value: event_guid
                        }
                        ]
                    },
                    left_icon: 'fa-solid fa-hashtag'
                },
                {
                    value: lo.name,
                    name: 'name',
                    label: 'Emri',
                    type: 'text-field',
                    style: 'flex-grow: 0.4',
                    required: true,
                    required_text: 'Kjo fushe eshte e detyrueshme'
                }],
                
            ]
        }
    }
}
