export default {
  form: (ev, cities) => {
    var selected_city = {};
    selected_city[ev.location] = ev.city;
    return {
      name: "Modifiko eventin",
      submit: {
        label: "Modifiko eventin",
        action: "submitEvent",
      },
      fields: [
        [ {
          value: ev.name,
          name: "name",
          label: "Emri",
          type: "text-field",
          // vif: [
          //   {field: "description",
          //   value_not: "aaaa"}
          // ],
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
          unique: {
            model: "event",
            field: "name",
            where: [],
          },
        },
          // {
          //   value: ev.code,
          //   name: "code",
          //   label: "Kodi",
          //   type: "text-field",
          //   required: true,
          //   required_text: "Kjo fushe eshte e pandryshueshme",
          //   unique: {
          //     model: "event",
          //     field: "code",
          //     where: [
          //       {
          //       column: 'guid',
          //       condition: '!=',
          //       value: ev.guid
            
          //   }]},
          //   left_icon: "fa-solid fa-hashtag",
          // },
          {
            value: ev.description,
            name: "description",
            label: "Pershkrimi",
            type: "text-field",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",

          }, {
            value: ev.active,
            name: "active",
            label: "Aktiv",
            type: "checkbox-field",
          },
          {
            value: ev.has_tickets,
            name: "has_tickets",
            label: "Ka Bileta",
            type: "checkbox-field",
          },

         
        ],
        [
          {
            value: ev.starts_at,
            name: "starts_at",
            label: "Data e nisjes",
            type: "datetime",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
          },

          {
            value: ev.ends_at,
            name: "ends_at",
            label: "Data e perfundimit",
            type: "datetime",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
          },
        ],
        [
         
          {
            value: ev.location,
            name: "location",
            label: "Vendndodhja",
            type: "tree-select",
            style: "flex-grow: 0.5",
            options: cities,
            selected_options: selected_city,
            mode: "single",
            required: true,
          },
        ],
      ],
    };
  },
};
