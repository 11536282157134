export default {
  form: (colors) => {
    return {
      name: "Krijo Tip",
      submit: {
        label: "Krijo",
        action: "submitTicketType",
      },
      fields: [
        [
          {
            value: "",
            name: "name",
            label: "Emri",
            type: "text-field",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
          },

          {
            value: "",
            name: "price",
            label: "Cmimi",
            type: "number-field",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",

            left_icon: "fa-solid fa-price",
          },
        ],
        [
          {
            value: "",
            name: "starts_at",
            label: "Data e nisjes",
            type: "datetime",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
          },

          {
            value: "",
            name: "ends_at",
            label: "Data e perfundimit",
            type: "datetime",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
          },
        ],
        [
          
          {
            value: "",
            name: "bgcolor",
            label: "Ngjyra e background",
            type: "tree-select",
            style: "flex-grow: 0.5, margin-right:1.5%",
            isColor: true,
            options: colors,
            selected_options: [],
            mode: "single",
            required: false,
          },
          {
            value: "",
            name: "fgcolor",
            label: "Ngjyra e foreground",
            type: "tree-select",
            style: "flex-grow: 0.5,  margin-left:1.5%",
            isColor: true,
            options: colors,
            selected_options: [],
            mode: "single",
            required: false,
          },
        ],
        [{
          value: "0",
          name: "quantity",
          label: "Sasia",
          type: "number-field",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
        },]
      ],
    };
  },
};
