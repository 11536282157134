<template>
  <div>
    <div class="column is-12">
      <div>
        <div class="">
          <div>
            <p class="is-size-5"></p>
          </div>
          <div
            v-for="(key, index) in loc_act"
            :key="index"
            class="has-border mb-2"
          >
            <p
              v-if="key.activities.length"
              class="py-1 px-2 has-text-dark"
              style="background: aliceblue"
            >
              <span class="is-capitalized">
                {{ key.location.name }}
              </span>
              <span
                @click="check_all_group(key)"
                class="is-pulled-right has-text-grey is-size-7 is-clickable"
              >
                <span v-if="are_all_checked(key)">
                  <i class="far fa-square"></i> {{ "Caktivizo te gjitha" }}
                </span>
                <span v-else>
                  <i class="far fa-check-square"></i>
                  {{ "Aktivizo te gjitha" }}
                </span>
              </span>
              <!-- </span> -->
            </p>
            <div class="is-flex is-flex-wrap-wrap">
              <div
                v-for="(item, indexa) in key.activities"
                :key="indexa"
                class="has-border is-flex is-flex-direction-column"
                style="
                  width: 15%;
                  border-bottom: solid 1px #dedede;
                  text-align: center;
                "
              >
                {{ item.name }}
                <div
                  class="is-flex-grow-2 is-flex is-justify-content-center is-align-items-center"
                >
                  <label class="checkbox py-1 px-6">
                    <input
                      :value="item.guid"
                      type="checkbox"
                      v-model="ticket_activities"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <a
              @click.prevent="save_ticket_activities()"
              class="button is-success is-small is-4 mb-3"
              href=""
              >{{ "Ruaj ndryshimet" }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import permissionMixin from "@/mixins/permissions-mixin.js";
import EventServices from "@/services/Event";

export default {
  mixins: [permissionMixin],

  props: ["type_guid", "event_guid"],
  data() {
    return {
      loc_act: {},
      tt_act: {},
      ticket_activities: [],
    };
  },

  async created() {
    this.loc_act = await EventServices.getLocationActivities({
      guid: this.type_guid,
      event_guid: this.event_guid,
    });
    this.ticket_activities = await EventServices.getTicketActivities({
      guid: this.type_guid,
      event_guid: this.event_guid,
    });
  },

  methods: {
    async save_ticket_activities() {
      var tmp = await EventServices.setTicketActivities({
        guid: this.type_guid,
        event_guid: this.event_guid,
        activities: this.ticket_activities,
      });
      this.ticket_activities = {};

      this.ticket_activities = tmp;
      let notify_text = "Activitetet u modifikuan me sukses!";
      this.edit_form = null;
      this.$root.selected_one = {};
      this.$toast.success(notify_text);
    },
    async select_location_activities() {},
    are_all_checked(k) {
      const checkeveryItem = k.activities.every((item) => {
        if (this.ticket_activities.indexOf(item.guid) !== -1) return true;
      });

      return checkeveryItem;
    },
    check_all_group(k) {
      // 1. Check if all are checked
      const areallchecked = this.are_all_checked(k);

      // 2. If all are checked -> uncheck all
      if (areallchecked === true) {
        const mapped = k.activities.map((pp) => pp.guid);

        this.ticket_activities = this.ticket_activities.filter(
          (el) => !mapped.includes(el)
        );
      } else {
        // 3. else -> Check all
        const mapped = k.activities.map((pp) => pp.guid);

        for (var i = 0; i < mapped.length; i++) {
          if (!this.ticket_activities.includes(mapped[i])) {
            this.ticket_activities.push(mapped[i]);
          }
        }
      }
      return areallchecked;
    },
  },
};
</script>

<style scoped></style>
