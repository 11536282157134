<template>
  <div>
    <div class="columns">
      <section class="flex">
        <form class="">
          <div class="form-group">
            <label class="text-gray-600 font-semibold text-lg">Ngjyra</label>
            <div
              v-for="(input, index) in colors"
              :key="`colorInput-${index}`"
              class="wrapper flex items-center"
            >
              <input
                type="color"
                v-model="input.code"
                class="h-10 w-40 rounded-lg outline-none p-2"
              />
              <!--          Add Svg Icon-->
              <svg
                @click="addField(input, colors)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="green"
                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                />
              </svg>

              <!--          Remove Svg Icon-->
              <svg
                v-show="colors.length > 1"
                @click="removeField(index, colors)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="red"
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                />
              </svg>
            </div>
          </div>
        </form>
      </section>
    </div>
    <div class="columns">
      <div class="column is-6">
        <button
          @click.prevent="save_color"
          class="button is-small mt-2 bg-success-button has-text-white"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import EventServices from "@/services/Event";
import Swal from "sweetalert2";

export default {
  props: ["event_guid"],
  components: {},
  data() {
    return {
      colors: [{ code: "#000000" }],
    };
  },
  methods: {
    addField(value, fieldType) {
      fieldType.push({ code: "#000000" });
    },
    removeField(index, fieldType) {
      // debugger; // eslint-disable-line no-debugger

      if (typeof fieldType[index].guid !== "undefined")
        Swal.fire({
          title: "Jeni te sigurt qe doni ta fshini?",
          text: "Nje tip bilete mund te jete duke e perdorur kete ngjyre",
          showCancelButton: true,
          confirmButtonText: "Fshije",
          confirmButtonColor: "#646f9b",
          cancelButtonColor: "#dfdfdf",
        }).then((result) => {
          if (result.isConfirmed) fieldType.splice(index, 1);
        });
      else fieldType.splice(index, 1);
    },
    async save_color() {
      var notify_text = "";
      let temp = await EventServices.addColorsToEvent(
        this.event_guid,
        this.colors
      );

      this.colors = temp;
      notify_text = "Ngjyrat u vendosen me sukses";
      this.$root.selected_one = {};

      this.$toast.success(notify_text);

    },
  },
  async created() {
    this.colors = await EventServices.getColorsByEvent(this.event_guid);
    if (Object.keys(this.colors).length === 0) {
      this.colors = [{ code: "#000000" }];
    }
  },
};
</script>
<style scoped>
.bg-success-button {
  background-color: rgb(100, 111, 155);
}
</style>
