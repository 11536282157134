export default {
  form: (loc) => {
    return {
      name: "Krijo Pike Shitje",
      submit: {
        label: "Krijo",
        action: "submitPos",
      },
      fields: [
        [{
          value: "",
          name: "name",
          label: "Emri",
          type: "text-field",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
        },
        {
          value: 0,
          name: "active",
          label: "Aktiv",
          type: "checkbox-field",
        }],
        {
          value: "",
          name: "code",
          label: "Kodi",
          type: "text-field",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",

          left_icon: "fa-solid fa-hashtag",
        },
        {
          name: "location",
          label: "Zona",
          type: "tree-select",
          style: "flex-grow: 0.5",
          options: loc,
          selected_options: [],
          mode: "single",
          required: true,
        },
      ],
    };
  },
};
