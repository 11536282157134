export default {
  form: (wa) => {
    return {
      name: "Modifiko Shitesin",
      submit: {
        label: "Modifiko Shites",
        action: "submitVendor",
      },
      fields: [
        [
          {
            value: wa.name,
            name: "name",
            label: "Emri",
            type: "text-field",
            style: "flex-grow: 0.4",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
          },
          {
            value: wa.code,
            name: "code",
            label: "Kodi",
            type: "text-field",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
            unique: {
              model: "warehouse",
              field: "code",
              where: [
                {
                  column: "guid",
                  condition: "!=",
                  value: wa.guid,
                },
              ],
            },
            left_icon: "fa-solid fa-hashtag",
          },
        ],
        // [
        //   {
        //     value: wa.location,
        //     name: "location",
        //     label: "Adresa",
        //     type: "text-field",
        //     style: "flex-grow: 0.4",
        //     required: true,
        //     required_text: "Kjo fushe eshte e detyrueshme",
        //   },
        // ],
      ],
    };
  },
};
