import Api from "@/services/Api";

export default {
  async getActivites(event_guid, location_guid) {
    var activities = [];
    await Api(true)
      .post(
        "activity/list/" + location_guid,
        {},
        {
          headers: {
            Event: event_guid,
          },
        }
      )
      .then((r) => {
        debugger; // eslint-disable-line no-debugger

        activities = r.data;
      });
    return activities;
  },

  async createActivity(activity, location_guid, event_guid) {
    await Api(true)
      .post("activity/create/" + location_guid, activity, {
        headers: {
          Event: event_guid,
        },
      })
      .then((r) => {

        activity = r.data;
      });
    return activity;
  },

  async editActivity(activity, location_guid, event_guid) {
    await Api(true)
      .post("activity/edit/" + location_guid, activity, {
        headers: {
          Event: event_guid,
        },
      })
      .then((r) => {

        activity = r.data;
      });
    return activity;
  },

  // async getLocations(event_guid) {
  //     var locations = []
  //     await Api(true).post("location/list/" + event_guid, {})
  //     .then(r => {
  //         locations = r.data.map(e => {
  //             e.label = e.name
  //             return e
  //         })
  //     })
  //     return locations
  // }
};
