<template>
  <div>
    <div class="columns">
      <div class="column is-9">
        <div>
          Tipet e Biletave
          <a @click.prevent="create_ticket_type">
            <i class="fa fa-plus"></i>
          </a>
        </div>
        <ag-grid-vue
          style="width: 100%; height: 200px"
          class="ag-theme-balham"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :animateRows="true"
          @grid-ready="onGridReady"
          :context="context"
        >
        </ag-grid-vue>
      </div>

      <div class="column is-3" v-if="edit_form || create_form || activities">
        <DaForm
          v-if="create_form"
          @submitted="submitTicketType"
          :form="create_form"
        ></DaForm>
        <DaForm
          v-if="edit_form"
          @submitted="submitTicketType"
          :form="edit_form"
        ></DaForm>
        <!-- <DaForm
          v-if="activities"
          @submitted="submitTicketTypeActivities"
          :form="tta_edit"
        ></DaForm> -->
      </div>
    </div>
    <div class="column is-12" v-if="activities">
      <TicketActivities :type_guid="type_guid" :event_guid="event_guid">
      </TicketActivities>
    </div>
    <!-- {{ selected_item.id }} -->
    <div v-if="selected_item.id">
      <div class="columns border-t-2 border-slate-200 mt-3">
        <div class="column is-12">
          <tickets-grid
            :type_guid="type_guid"
            :event_guid="event_guid"
          ></tickets-grid>
        </div>
        <div class="column is-8">
          <div class=""></div>
          <div
            class="px-2"
            style="border: solid 1px #dbdbdb; border-width: 0px 1px"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import EditViewTickets from "@/components/buttonscell/EditViewTickets.vue";
import CreateTicketType from "../forms/ticket_types/create";
import EditTicketType from "../forms/ticket_types/edit";
// import EditTicketTypeActivities from "../forms/ticket_activities/edit";
import DaForm from "@/components/daform/index.vue";
import EventServices from "@/services/Event";
import TicketsGrid from "../components/tickets-grid";
import TicketActivities from "../components/ticket-activities";
import helpers from "@/helpers.js";

import { AgGridVue } from "ag-grid-vue3";
export default {
  props: ["event_guid"],
  components: {
    AgGridVue,
    DaForm,
    TicketActivities,
    TicketsGrid,
  },
  data() {
    return {
      context: null,
      rowData: [],
      has_tickets_flag: 0,
      create_form: null,
      edit_form: null,
      tta_edit: null,
      type_guid: {},
      colors: {},
      loc_act: {},
      ticket_act: {},
      activities: 0,
      getRowStyle: null,
      columnDefs: [
        {
          headerName: "Emri",
          field: "name",
          filter: true,
          floatingFilter: true,
          sortable: true,
          sort: "asc",
          cellStyle: (params) => {
            return {
              color: params.data.fg,
              backgroundColor: params.data.bg,
            };
          },
        },

        {
          headerName: "Data e fillimit",
          field: "starts_at", //
          filter: true,
          floatingFilter: true,
          valueFormatter: helpers.dateFormatter,

          sortable: true,
        },
        {
          headerName: "Data e mbarimit",
          field: "ends_at", //TODO : RENDERIZIM ME EURO
          filter: true,
          floatingFilter: true,
          valueFormatter: helpers.dateFormatter,
          sortable: true,
        },
        {
          headerName: "Cmimi",
          field: "price_formatted", //TODO : RENDERIZIM ME EURO
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Sasia",
          field: "quantity", //TODO : RENDERIZIM ME EURO
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Action",
          cellRenderer: EditViewTickets,
          cellRendererParams: this.context,
        },
      ],
      gridApi: null,
      gridColumnApi: null,
      selected_item: {},
      selectedType: {},
      rowSelection: "single",
    };
  },

  beforeMount() {
    this.context = { componentParent: this };
  },

  methods: {
    create_ticket_type() {
      this.edit_form = null;
      this.tta_edit = null;
      this.create_form = CreateTicketType.form(this.colors);
    },

    async open(mode, ticket_type) {
      if (mode == "1") {
        this.create_form = null;
        this.tta_edit = null;
        if (ticket_type) {
          this.edit_form = null;
          setTimeout(() => {
            let form = EditTicketType.form(ticket_type, this.colors);
            this.selectedType = ticket_type;

            this.edit_form = form;
            this.selected_item = {};
            this.activities = 0;
          });
        }
      } else if (mode == "0") {
        this.create_form = null;
        this.tta_edit = null;
        this.selected_item = {};
        let temp = ticket_type;
        this.activities = 0;

        this.selected_item = temp;
        // this.event_guid = event.guid;
        this.type_guid = ticket_type.guid;
        this.rowData = await EventServices.getTicketTypes({
          guid: this.event_guid,
        });
      } else if (mode == "2") {
        this.create_form = null;
        this.tta_edit = null;
        this.selected_item = {};
        this.type_guid = ticket_type.guid;

        this.loc_act = await EventServices.getLocationActivities({
          guid: this.type_guid,
          event_guid: this.event_guid,
        });
        // debugger; // eslint-disable-line no-debugger

        this.tt_act = await EventServices.getTicketActivities({
          guid: this.type_guid,
          event_guid: this.event_guid,
        });

        // setTimeout(() => {
        //   let form = EditTicketTypeActivities.form(ticket_type, this.loc_act);
        //   this.selectedType = ticket_type;

        //   this.tta_edit = form;
        // });
        this.activities = 1;
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
    },
    async submitTicketType(tt) {
      var notify_text = "";
      let tte = {};
      tte["ticket_type"] = tt;
      tte["event"] = { event_guid: this.event_guid };
      if (this.create_form != null) {
        let tempTick = await EventServices.createTicketType(tte);
        if (tempTick == "error") {
          notify_text = "Ju lutemi, kontrolloni datat!";
          this.$toast.error(notify_text);
          return 0;
        }
        this.rowData.push(tempTick);
        notify_text = "Tipi i Biletes u krijua me sukses!";
        this.create_form = null;
        this.$root.selected_one = {};
        this.$toast.success(notify_text);

      } else {
        tt.guid = this.selectedType.guid;
        tt.event_guid = this.event_guid;

        let ticktype = await EventServices.editTicketType(tt);
        if (ticktype == "error") {
          notify_text = "Ju lutemi, kontrolloni datat!";
          this.$toast.error(notify_text);
          return 0;
        }
        this.rowData = [
          ...this.rowData.map((tit) => {
            if (tit.guid == ticktype.guid) tit = { ...ticktype };
            return tit;
          }),
        ];

        notify_text = "Tipi i biletes u modifikua me sukses!";
        this.edit_form = null;
        this.$root.selected_one = {};
        this.$toast.success(notify_text);
      }
    },
  },
  async created() {
    this.colors = await EventServices.getColorsByEvent(this.event_guid);

    this.rowData = await EventServices.getTicketTypes({
      guid: this.event_guid,
    });

    this.getRowStyle = (params) => {
      return { color: params.data.fg, backgroundColor: params.data.bg };
    };
  },
};
</script>
