export default {
    form: (location_guid) => {
      return {
        name: "Krijo Aktivitet",
        submit: {
          label: "Krijo",
          action: "submitActivity",
        },
        fields: [
          [
            {
              value: "",
              name: "code",
              label: "Kodi",
              type: "text-field",
              style: "flex-grow: 0.3",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
              unique: {
                model: "activity",
                field: "code",
                where: [
                  {
                    columns: 'location_guid',
                    condition: '=',
                    value: location_guid
                  }
                ],
              },
              left_icon: "fa-solid fa-hashtag",
            },
            {
              value: "",
              name: "name",
              label: "Emri",
              type: "text-field",
              style: "flex-grow: 0.3",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
            },
            {
              value: "",
              name: "max_capacity",
              label: "Kapaciteti",
              type: "number-field",
              style: "flex-grow: 0.3",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
            }
          ],
          [
            {
                value: "",
                name: "starts_at",
                label: "Fillon",
                type: "datetime",
                style: "flex-grow: 0.4",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
            {
                value: "",
                name: "ends_at",
                label: "Mbaron",
                type: "datetime",
                style: "flex-grow: 0.4",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            }
          ]
        ],
      };
    },
  };
  
  