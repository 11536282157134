export default {
  form: (ticket_type, colors) => {
    var bg = {};
    var fg = {};
    bg[ticket_type.bgcolor] = ticket_type.bg;
    fg[ticket_type.fgcolor] = ticket_type.fg;
    // bg[ticket_type.fg] = ticket_type.fg;
    return {
      name: "Modifiko tipin e Biletes",
      submit: {
        label: "Modifiko tipin e Biletes",
        action: "submitTicketType",
      },
      fields: [
        [
          {
            value: ticket_type.name,
            name: "name",
            label: "Emri",
            type: "text-field",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
          },
          {
            value: ticket_type.price,
            name: "price",
            label: "Cmimi",
            type: "number-field",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
          },
        ],
        [
          {
            value: ticket_type.starts_at,
            name: "starts_at",
            label: "Data e nisjes",
            type: "datetime",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
          },

          {
            value: ticket_type.ends_at,
            name: "ends_at",
            label: "Data e perfundimit",
            type: "datetime",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
          },
        ],
        [
          {
            value: ticket_type.quantity,
            name: "quantity",
            label: "Sasia",
            type: "number-field",
          },
        ],
        [
          {
            value: ticket_type.bgcolor,
            name: "bgcolor",
            label: "Ngjyra e background",
            type: "tree-select",
            style: "flex-grow: 0.5, margin-right:1.5%",
            isColor: true,
            options: colors,
            selected_options: bg,
            mode: "single",
            required: false,
            excluded_options: ["fgcolor"],
          },
          {
            value: ticket_type.fgcolor,
            name: "fgcolor",
            label: "Ngjyra e foreground",
            type: "tree-select",
            style: "flex-grow: 0.5,  margin-left:1.5%",
            isColor: true,
            options: colors,
            selected_options: fg,
            mode: "single",
            excluded_options: ["bgcolor"],
            required: false,
          },
        ],
      ],
    };
  },
};
