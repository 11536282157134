import Api from "@/services/Api";

export default {
  async getEvents() {
    var events = [];
    await Api(true)
      .post("event/list", {})
      .then((r) => {
        events = r.data;
      });
    return events;
  },

  async get(ev) {
    var event = {};
    await Api(true)
      .post("event/details", ev)

      .then((r) => {
        event = r.data;
      });
    return event;
  },

  async createEvent(event) {
    await Api(true)
      .post("event/create", event)
      .then((r) => {
        if (r.data.msg == "error") {
          event = "error";
        } else event = r.data.data;
      });

    return event;
  },

  async editEvent(event) {
    await Api(true)
      .post("event/edit", event)
      .then((r) => {
        if (r.data.msg == "error") {
          event = "error";
        } else event = r.data.data;
      });
    return event;
  },

  async getTicketTypes(event) {
    await Api(true)
      .post("event/ticket-types", event, {
        headers: {
          Event: event.guid,
        },
      })
      .then((r) => {
        event = r.data;
      });
    return event;
  },
  async createTicketType(ticket_type) {
    await Api(true)
      .post("event/create-ticket-type", ticket_type, {
        headers: {
          Event: ticket_type.event.event_guid,
        },
      })
      .then((r) => {
        if (r.data.msg == "error") {
          ticket_type = "error";
        } else ticket_type = r.data.data;
      });
    return ticket_type;
  },
  async editTicketType(ticket_type) {
    await Api(true)
      .post("event/edit-ticket-type", ticket_type, {
        headers: {
          Event: ticket_type.event_guid,
        },
      })
      .then((r) => {
        if (r.data.msg == "error") {
          ticket_type = "error";
        } else ticket_type = r.data.data;
      });
    return ticket_type;
  },
  async getTicketsByType(ticket_guid) {
    await Api(true)
      .post("event/tickets-by-type", ticket_guid)
      .then((r) => {
        ticket_guid = r.data;
      });
    return ticket_guid;
  },
  async createTicket(tick) {
    await Api(true)
      .post("event/create-ticket", tick)
      .then((r) => {
        tick = r.data;
      });
    return tick;
  },

  async getLocationActivities(activities) {
    await Api(true)
      .post("event/get-location-activities", activities, {
        headers: {
          Event: activities.event_guid,
        },
      })
      .then((r) => {
        activities = r.data;
      });
    return activities;
  },
  async getTicketActivities(tt) {
    await Api(true)
      .post("event/get-ticket-activities", tt, {
        headers: {
          Event: tt.event_guid,
        },
      })
      .then((r) => {
        tt = r.data;
      });

    return tt;
  },
  async setTicketActivities(tt) {
    await Api(true)
      .post("event/set-ticket-activities", tt, {
        headers: {
          Event: tt.event_guid,
        },
      })
      .then((r) => {
        tt = r.data;
      });
    // debugger; // eslint-disable-line no-debugger

    return tt;
  },

  //get vendors for foreign events

  async getVendors(event) {
    let vendors = {};
    await Api(true)
      .post("event/vendors", event, {
        headers: {
          Event: event.guid,
        },
      })
      .then((r) => {
        vendors = r.data;
        // console.log(vendors);
      });
    return vendors;
  },
  async createVendor(event) {
    let vendors = {};
    await Api(true)
      .post("event/create-vendor", event, {
        headers: {
          Event: event.event,
        },
      })
      .then((r) => {
        vendors = r.data;
      });
    return vendors;
  },
  async editVendor(event, vendor) {
    let vendors = {};
    await Api(true)
      .post("event/edit-vendor", vendor, {
        headers: {
          Event: event,
        },
      })
      .then((r) => {
        vendors = r.data;
      });
    return vendors;
  },

  async getPosByVendor(event) {
    let vendors = {};
    await Api(true)
      .post("event/vendor-pos", event, {
        headers: {
          Event: event.event_guid,
        },
      })
      .then((r) => {
        vendors = r.data;
      });
    return vendors;
  },
  async createPointOfSale(event) {
    let vendors = {};
    await Api(true)
      .post("event/create-vendor-pos", event, {
        headers: {
          Event: event.event_guid,
        },
      })
      .then((r) => {
        vendors = r.data;
      })
      .catch((error) => {
        // debugger; // eslint-disable-line no-debugger
        console.log(error);
        vendors = "error";
      });
    return vendors;
  },
  async editPointOfSale(event) {
    let vendors = {};
    await Api(true)
      .post("event/edit-vendor-pos", event, {
        headers: {
          Event: event.event_guid,
        },
      })
      .then((r) => {
        vendors = r.data;
      })
      .catch((error) => {
        console.log(error);
        vendors = "error";
      });
    return vendors;
  },

  async getColorsByEvent(event) {
    let colors = {};
    await Api(true)
      .post("event/color-palette", event, {
        headers: {
          Event: event,
        },
      })
      .then((r) => {
        colors = r.data.map((e) => {
          e.id = e.guid;

          e.label = e.code;
          e.name = e.code;
          return e;
        });
      });
    return colors;
  },
  async addColorsToEvent(event, col) {
    let colors = {};
    await Api(true)
      .post("event/add-color-palette", col, {
        headers: {
          Event: event,
        },
      })
      .then((r) => {
        colors = r.data;
      });
    return colors;
  },
};
