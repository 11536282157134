<template>
  <div class="buttons-block is-flex is-justify-content-space-between">
    <a href="" @click.prevent="open_row(1)">
      <i title="Modifiko" class="fa fa-pencil"></i>
    </a>
    <div>
      <a href="" @click.prevent="open_row(0)">
        <i title="Shiko" class="fa fa-ticket"></i>
      </a>
    </div>
    <div>
      <a href="" @click.prevent="open_row(2)">
        <i title="Shiko" class="fa fa-folder"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["params"],
  data() {
    return {
      displayValue: 1,
    };
  },
  methods: {
    open_row(m) {
      this.params.context.componentParent.open(m, this.params.data);
    },
  },
};
</script>

<style>
.buttons-block {
  max-width: 70px;
  margin: auto;
}
</style>
