export default {
    form: () => {
      return {
        name: "Krijo bilete",
        submit: {
          label: "Krijo",
          action: "submitTicket",
        },
        fields: [
            {
              value: "",
              name: "barcode",
              label: "Kodi",
              type: "text-field",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
              unique: {
                model: "event_tickets",
                field: "barcode",
                where: [],
              },
              left_icon: "fa-solid fa-hashtag",
            },
        ],
      };
    },
  };
