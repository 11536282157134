<template>
  <div>
    <div class="columns">
      <div class="column">
        <div>
          Biletat e Eventit
          <a @click.prevent="create_ticket">
            <i class="fa fa-plus"></i>
          </a>
        </div>
        <ag-grid-vue
          style="width: 100%; height: 200px"
          class="ag-theme-balham"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :animateRows="true"
          @grid-ready="onGridReady"
          :context="context"
        >
        </ag-grid-vue>
      </div>
      <div v-if="create_form" class="column is-2">
        <DaForm
          v-if="create_form"
          @submitted="submitTicket"
          :form="create_form"
        >
        </DaForm>
      </div>
    </div>
  </div>
</template>
<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import EventServices from "@/services/Event";
import CreateTicket from "../forms/tickets/create";
import DaForm from "@/components/daform/index.vue";

export default {
  props: ["type_guid", "event_guid"],
  components: {
    AgGridVue,
    DaForm,
  },
  data() {
    return {
      context: null,
      rowData: [],
      create_form: null,
      edit_form: null,
      selected_item: [],
      columnDefs: [
        {
          headerName: "Barkodi",
          field: "barcode",
          filter: true,
          floatingFilter: true,
          sortable: true,
          sort: "asc",
        },
        {
          headerName: "Tipi i Biletes",
          field: "type_name",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Klienti",
          field: "customer_name",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Cmimi",
          field: "ticket_price", //TODO : RENDERIZIM ME EURO
          filter: true,
          floatingFilter: true,
          sortable: true,
        },

        {
          headerName: "Checked-in by",
          field: "checked_in_by",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Checked-in at",
          field: "checked_in_at",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
      ],
      gridApi: null,
      rowSelection: "single",

      gridColumnApi: null,
    };
  },

  beforeMount() {
    this.context = { componentParent: this };
  },

  async created() {
    this.rowData = await EventServices.getTicketsByType({
      type_guid: this.type_guid,
    });
  },

  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
    },
    create_ticket() {
      this.edit_form = null;
      this.create_form = CreateTicket.form();
    },
    async submitTicket(t) {
      var notify_text = "";
      let tt = {
        type_guid: this.type_guid,
        event_guid: this.event_guid,
        ticket: t,
      };
      if (this.create_form != null) {
        this.rowData.push(await EventServices.createTicket(tt));
        notify_text = "Eventi u krijua me sukses!";
        this.create_form = null;
        this.$root.selected_one = {};
      }
      this.$toast.success(notify_text);
    },
  },
};
</script>
