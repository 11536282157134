import Api from "@/services/Api";

export default {
  async getLocations(event_guid) {
    var locations = [];
    await Api(true)
      .post(
        "location/list/" + event_guid,
        {},
        {
          headers: {
            Event: event_guid,
          },
        }
      )
      .then((r) => {
        locations = r.data.map((e) => {
          e.id = e.guid;

          e.label = e.name;
          return e;
        });
      });

    return locations;
  },

  async createLocation(location, event_guid) {
    await Api(true)
      .post("location/create/" + event_guid, location, {
        headers: {
          Event: event_guid,
        },
      })
      .then((r) => {
        location = r.data;
      });
    return location;
  },

  async editLocation(location, event_guid) {
    await Api(true)
      .post("location/edit/" + event_guid, location, 
      {
        headers: {
          Event: event_guid,
        },
      })
      .then((r) => {
        location = r.data;
      });

      return location;

  },

  async getEvents() {
    var events = [];
    await Api(true)
      .post("event/list", {})
      .then((r) => {
        events = r.data.map((e) => {
          e.label = e.name;
          return e;
        });
      });
    return events;
  },
};
