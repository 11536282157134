<template>
  <div class="buttons-block is-flex is-justify-content-space-between">
    <div>
      <a href="" @click.prevent="open_row(1)">
        <i title="Modifiko" class="fa fa-pencil"></i>
      </a>
    </div>
    <div>
      <a href="" @click.prevent="open_row(2)">
        <i title="Shiko Pikat e Shitjes" class="fa fa-store"></i>
      </a>
    </div>
    <div>
      <a href="" @click.prevent="open_row(3)">
        <i title="Perdoruesit" class="fa fa-user"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["params"],
  data() {
    return {
      displayValue: 1,
      isForeign: 0,
    };
  },
  methods: {
    open_row(m) {
      this.params.context.componentParent.open(m, this.params.data);
    },
  },
  async created() {
    // setTimeout(() => {
    //   if (this.params.data.has_tickets !== "undefined") {
    //     this.displayValue = this.params.data.has_tickets;
    //   }
    //   if (this.params.data.type !== "undefined") {
    //     this.isForeign = this.params.data.type;
    //   }
    // });
  },
};
</script>

<style>
.buttons-block {
  max-width: 70px;
  margin: auto;
}
</style>
