export default {
  form: (cities, abs) => {
    return {
      name: "Krijo event",
      submit: {
        label: "Krijo",
        action: "submitEvent",
      },
      fields: [
        [
          {
            name: "type",
            label: "Tipi",
            type: "tree-select",
            style: "width: 30%",
            options: abs,
            required_text: "Kjo fushe eshte e detyrueshme",
            selected_options: [],
            mode: "single",
            required: true,
          },
        ],
        [
          {
            value: "",
            name: "code",
            label: "Kodi",
            type: "text-field",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
            unique: {
              model: "event",
              field: "code",
              where: [],
            },
            left_icon: "fa-solid fa-hashtag",
          },
          {
            value: "",
            name: "description",
            label: "Pershkrimi",
            type: "text-field",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",

            // left_icon: "fa-solid fa-note",
          },

          {
            value: "",
            name: "name",
            label: "Emri",
            type: "text-field",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
            unique: {
              model: "event",
              field: "name",
              where: [],
            },
            // left_icon: 'fa-solid fa-hashtag',
          },
        ],
        [
          {
            value: "",
            name: "starts_at",
            label: "Data e nisjes",
            type: "datetime",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
          },

          {
            value: "",
            name: "ends_at",
            label: "Data e perfundimit",
            type: "datetime",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
          },
        ],
        [
          {
            name: "location",
            label: "Vendndodhja",
            type: "tree-select",
            style: "flex-grow: 0.5",
            options: cities,
            selected_options: [],
            mode: "single",
            required: true,
          },
          {
            value: 0,
            name: "active",
            label: "Aktiv",
            type: "checkbox-field",
          },
          {
            value: 0,
            name: "has_tickets",
            label: "Ka Bileta",
            type: "checkbox-field",
          },
        ],
      ],
    };
  },
};
