<template>
  <div>
    <i
      :class="[params.value == 1 ? 'far fa-check-circle' : 'fa fa-times-circle']"
    >
    </i>
  </div>
</template>

<script>
export default {

};
</script>
