export default {
  form: () => {
    return {
      name: "Krijo Shites",
      submit: {
        label: "Krijo Shites",
        action: "submitVendor",
      },
      fields: [
        {
          value: "",
          name: "name",
          label: "Emri",
          type: "text-field",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
        },
        {
          value: "",
          name: "code",
          label: "Kodi",
          type: "text-field",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",

          left_icon: "fa-solid fa-hashtag",
        },
      ],
    };
  },
};
