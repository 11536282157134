export default {
  form: (pos, loc) => {
    var selected_loc = {};

    selected_loc[pos.location] = pos.zona;
    return {
      name: "Modifiko Dyqanin",
      submit: {
        label: "Modifiko Dyqanin",
        action: "submitPos",
      },
      fields: [
        {
          value: pos.name,
          name: "name",
          label: "Emri",
          type: "text-field",
          style: "flex-grow: 0.4",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
        },
        [
          {
            value: pos.code,
            name: "code",
            label: "Kodi",
            type: "text-field",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",

            left_icon: "fa-solid fa-hashtag",
          },
          {
            value: pos.active,
            name: "active",
            label: "Aktiv",
            type: "checkbox-field",
          },
        ],
        [
          {
            value: pos.location,
            name: "location",
            label: "Zona",
            type: "tree-select",
            style: "flex-grow: 0.5",
            options: loc,
            selected_options: selected_loc,
            mode: "single",
            required: true,
          },
        ],
      ],
    };
  },
};
