<template>
  <div>
    <div class="columns">
      <div class="column">
        <div>
          Pikat e Shitjes per {{ warehouse.code }}
          <!-- {{event_guid}} -->
          <!-- {{ warehouse_id }} -->
          <a @click.prevent="create_pos">
            <i class="fa fa-plus"></i>
          </a>
        </div>
        <ag-grid-vue
          style="width: 100%; height: 200px"
          class="ag-theme-balham"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :animateRows="true"
          @grid-ready="onGridReady"
          :context="context"
        >
        </ag-grid-vue>
      </div>
      <div v-if="create_form || edit_form" class="column is-4">
        <DaForm v-if="create_form" @submitted="submitPos" :form="create_form">
        </DaForm>
        <DaForm v-if="edit_form" @submitted="submitPos" :form="edit_form">
        </DaForm>
      </div>
    </div>
  </div>
</template>
<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import EventServices from "@/services/Event";
import LocationServices from "@/services/Location";
import CreatePos from "../forms/pos/create";
import EditPos from "../forms/pos/edit";
import DaForm from "@/components/daform/index.vue";
import CheckboxCell from "@/components/checkboxcell/CheckboxCell.vue";
import EditCell from "@/components/buttonscell/EditCell.vue";

export default {
  props: ["warehouse", "warehouse_id", "event_guid"],
  components: {
    AgGridVue,
    DaForm,
  },
  data() {
    return {
      context: null,
      rowData: [],
      create_form: null,
      edit_form: null,
      locations: {},
      selected_item: [],
      selected_pos: {},
      columnDefs: [
        {
          headerName: "Kodi",
          field: "code",
          filter: true,
          floatingFilter: true,
          sortable: true,
          sort: "asc",
        },
        {
          headerName: "Emri",
          field: "name",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Zona",
          field: "zona",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Aktiv",
          field: "active",
          filter: true,
          floatingFilter: true,
          sortable: true,
          cellRenderer: CheckboxCell,
          cellStyle: function () {
            return {
              textAlign: "center",
            };
          },
        },

        {
          headerName: "Action",
          cellRenderer: EditCell,
          cellRendererParams: this.context,
        },
      ],
      gridApi: null,
      rowSelection: "single",

      gridColumnApi: null,
    };
  },

  beforeMount() {
    this.context = { componentParent: this };
  },

  async created() {

    this.rowData = await EventServices.getPosByVendor({
      event_guid: this.event_guid,
      warehouse_guid: this.warehouse_id,
    });
  },

  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
    },
    async create_pos() {
      this.edit_form = null;
      this.locations = await LocationServices.getLocations(this.event_guid);
      this.create_form = CreatePos.form(this.locations);

    },
    async submitPos(pos) {
      var notify_text = "";
      let tt = {
        warehouse_guid: this.warehouse_id,
        event_guid: this.event_guid,
        points_of_sale: pos,
      };
      let a = null;

      if (this.create_form != null) {
        a = await EventServices.createPointOfSale(tt);
        if (a == "error") {
          notify_text = "Pika e shitjes nuk u krijua!";
          this.$toast.error(notify_text);
        } else {
          this.rowData.push(a);
          notify_text = "Pika e shitjes u krijua me sukses!";
          this.create_form = null;
          this.$root.selected_one = {};
          this.$toast.success(notify_text);
        }
      } else {
        tt.guid = this.selected_pos.guid;
        a = await EventServices.editPointOfSale(tt);

        if (a == "error") {
          notify_text = "Pika e shitjes nuk u modifikua!";
          this.$toast.error(notify_text);
        } else {
          this.rowData = [
            ...this.rowData.map((tit) => {
              if (tit.guid == a.guid) tit = { ...a };
              return tit;
            }),
          ];
          notify_text = "Pika e shitjes u modifikua me sukses!";
          this.edit_form = null;
          this.$root.selected_one = {};
          this.$toast.success(notify_text);
        }
      }
    },

    async open(mode, pos) {
      if (mode == "1") {
        //editimi

        this.create_form = null;
        if (pos) {
          this.edit_form = null;
          this.locations = await LocationServices.getLocations(this.event_guid);
                  debugger; // eslint-disable-line no-debugger

          setTimeout(() => {
            let form = EditPos.form(pos, this.locations);

            this.edit_form = form;
            this.selected_pos = pos;
            this.selected_item = {};
            // this.selected_warehouse = {};

          });
        }
      }
    },
  },
};
</script>
