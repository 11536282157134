export default{
    form: (ac, location_guid) => {
        return {
            name: "Modifiko aktivitetin",
            submit: {
                label: "Modifiko aktivitetin",
                action: 'submitActivity'
            },
            fields: [

                [{
                    value: ac.code,
                    name: 'code',
                    label: 'Kodi',
                    type: 'text-field',
                    required: true,
                    required_text: 'Kjo fushe eshte e detyrueshme',
                    unique: {
                        model: 'activity',
                        field: 'code',
                        where: [
                            {
                            column: 'guid',
                            condition: '!=',
                            value: ac.guid
                        },
                        {
                            columns: 'location_guid',
                            condition: '=',
                            value: location_guid
                        }
                        ]
                    },
                    left_icon: 'fa-solid fa-hashtag'
                },
                {
                    value: ac.name,
                    name: 'name',
                    label: 'Emri',
                    type: 'text-field',
                    style: 'flex-grow: 0.3',
                    required: true,
                    required_text: 'Kjo fushe eshte e detyrueshme'
                },
                {
                    value: ac.max_capacity,
                    name: 'max_capacity',
                    label: 'Kapaciteti',
                    type: 'number-field',
                    style: 'flex-grow: 0.3',
                    required: true,
                    required_text: 'Kjo fushe eshte e detyrueshme'
                }],
                [
                    {
                        value: ac.starts_at,
                        name: 'starts_at',
                        label: 'Fillon',
                        type: 'datetime',
                        style: 'flex-grow: 0.4',
                        required: true,
                        required_text: 'Kjo fushe eshte e detyrueshme'
                    },
                    {
                        value: ac.ends_at,
                        name: 'ends_at',
                        label: 'Mbaron',
                        type: 'datetime',
                        style: 'flex-grow: 0.4',
                        required: true,
                        required_text: 'Kjo fushe eshte e detyrueshme'
                    },
                ]
            ]
        }
    }
}
