<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="level p-0 m-0">
          <div class="level-left">
            <div class="level-item">Lista e aktiviteteve</div>
          </div>
          <div class="level-right">
            <div class="level">
              <a @click.prevent="create_activity">
                <span class="mr-2">Krijo</span>
                <i class="fa fa-plus"></i>
              </a>
            </div>
          </div>
        </div>
        <ag-grid-vue
          style="width: 100%; height: 200px"
          class="ag-theme-balham"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :rowSelection="rowSelection"
          :context="context"
          :localeText="localeText"
          :animateRows="true"
          @grid-ready="onGridReady"
        >
        </ag-grid-vue>
      </div>
      <div class="column is-6" v-if="form_edit || form_create">
        <DaForm
          v-if="form_create"
          @submitted="submitActivity"
          :form="form_create"
        >
        </DaForm>

        <DaForm v-if="form_edit" @submitted="submitActivity" :form="form_edit">
        </DaForm>
      </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
import createActivity from "./forms/create";
import editActivity from "./forms/edit";
import ActivitiesServices from "@/services/Activity";
import locale from "@/components/ag-grid/locale/al/al.js";
import helpers from "@/helpers.js";
import ButtonsCell from "@/components/buttonscell/ButtonsCell.vue";
export default {
  components: {
    DaForm,
    AgGridVue,
  },
  props: ["location", "event"],
  beforeMount() {
    this.context = { componentParent: this };
  },
  data() {
    return {
      global_search_filter: "",
      context: null,
      selectedActivity: {},
      // locations: [],
      form_create: null,
      form_edit: null,
      columnDefs: [
        {
          headerName: "Kodi",
          field: "code",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Emri",
          field: "name",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Vendodhja",
          field: "location_name",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Kapaciteti",
          field: "max_capacity",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Fillon",
          field: "starts_at",
          valueFormatter: helpers.dateFormatter,
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Mbaron",
          field: "ends_at",
          valueFormatter: helpers.dateFormatter,
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Action",
          cellRenderer: ButtonsCell,
          cellRendererParams: this.context,
        },
      ],
      gridApi: null,
      defaultColDef: {
        flex: 1,
        minWidth: 100,
      },
      rowSelection: "single",
      localeText: null,
      rowData: [],
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
    },

    create_activity() {
      this.form_edit = null;
      this.form_create = createActivity.form(this.route_param);
    },
    open(mode, activity) {
      if (mode == "1") {
        this.form_create = null;
        if (activity) {
          this.form_edit = null;
          setTimeout(() => {
            let form = editActivity.form(
              activity,
              this.route_param,
              this.event
            );

            this.form_edit = form;
            this.selectedActivity = activity;
          });
        }
      }
    },
    async submitActivity(act) {
      var notify_text = "";
      if (this.form_create != null) {
        let activity = await ActivitiesServices.createActivity(
          act,
          this.route_param,

          this.event
        );
        act.location_guid = this.$route.params.location_guid;
        var a = this.rowData;
        a.push(activity);
        this.rowData = [...a];
        notify_text = "Aktiviteti u krijua me sukses";
        this.form_create = null;
        this.$root.selected_one = {};
      } else {
        act.guid = this.selectedActivity.guid;
        let activity = await ActivitiesServices.editActivity(
          act,
          this.route_param,
          this.event
        );
        this.rowData = [
          ...this.rowData.map((a) => {
            if (a.guid == activity.guid) a = activity;
            return a;
          }),
        ];
        notify_text = "Aktiviteti u modifikua me sukses";
        this.form_edit = null;
        this.$root.selected_one = {};
      }
      this.$toast.success(notify_text);
    },
  },
  computed: {
    route_param() {
      return this.location.guid;
    },
  },
  async created() {
    this.localeText = locale.locale_al();

    this.rowData = await ActivitiesServices.getActivites(
      this.event,
      this.route_param
      //   this.$route.params.location_guid
    );
    // this.locations = await ActivitiesServices.getLocations()
  },
};
</script>

<style lang="scss" scoped></style>
